import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import 'bootstrap';
import './Editor.sass';
import * as caret from 'caret-pos';
import  * as utils from './utils';

const config = utils.getConfiguration()
// A compound letter can't exceed this many characters
const MAX_LETTER_COMBINATION = 20

function EditorTextbox(props) {
    const {charMap} = props;

    const onTextChanged = (event) => {
        const textToCoptic = (text, cursorPos) => {
            // Find Start and End Position for current word
            let wordStart = cursorPos < MAX_LETTER_COMBINATION ? 0: cursorPos - MAX_LETTER_COMBINATION
            let word = text.substring(wordStart, cursorPos)
            if (!word.trim()) {
                return text
            }
            charMap.forEach(([fromChar, toChar]) => {
                if (!fromChar) return
                if (word.endsWith(fromChar)) {
                    word = word.substring(0, word.length - fromChar.length) + toChar
                }
            })
            return text.substring(0, wordStart) + word + text.substring(cursorPos)
        }

        const textbox = event.target;
        let originalValue = textbox.value;
        // Update textbox's size depending on how many lines are there
        if (!config.fixedRows) {
            let total_rows = originalValue.split("\n").length + 2;
            if (total_rows < config.rows) {
                total_rows = config.rows;
            }
            textbox.rows = total_rows;
        }

        // Do the character translation
        const cursor_position = caret.position(textbox).pos;
        let value = textToCoptic(originalValue, cursor_position);
        // Inform parent window that text has changed
        if (config.textChangeEvent) {
            utils.sendEvent("textChanged", value);
        }
        if (value !== originalValue) {
            // If the text has changed, set the new text and adjust caret position
            textbox.value = value;
            const change_in_text_len = originalValue.length - value.length;
            caret.position(textbox, cursor_position - change_in_text_len);
        }
    };

    const onSelect = (event) => {
        if (config.caretPositionEvent) {
            const caret_position = caret.position(event.target).pos;
            utils.sendEvent("caretPositionChanged", caret_position);
        }
    }

    const onBlur = () => {
        if (config.blurEvent) {
            utils.sendEvent("blur");
        }
    }

    return (
        <textarea
            id="mainTextarea"
            className="form-control"
            onChange={onTextChanged}
            onSelect={onSelect}
            rows={config.rows}
            placeholder={config.placeholder}
            readOnly={config.readonly}
            defaultValue={decodeURI(config.initialText)}
            onBlur={onBlur}
        ></textarea>
    )
}

EditorTextbox.propTypes = {
    charMap: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
}

function Editor() {
    const [charMap, setCharMap] = useState(config.defaultMap);
    const [defaultCharMap, setDefaultCharMap] = useState(config.defaultMap);
    const [title, setTitle] = useState(null);

    const getMainTextbox = () => {
        return document.getElementById("mainTextarea")
    }

    useEffect(() => {
        if (title) return;
        // Runs after the first render() lifecycle
        utils.loadWritingSystem(config.writingSystem).then((data) => {
            const {defaultCharMap, title} = data;
            document.title = title;
            setTitle(title);
            setDefaultCharMap(defaultCharMap);
            if (!charMap.length) {
                setCharMap(defaultCharMap);
            }
            // Inform parent window that editor has been initialized
            utils.sendEvent("init", null);
        })
    }, [title, charMap.length]);

    useEffect(() => {
         window.addEventListener(
             'message', function(event) {
                 const command = event.data.type;
                 switch (command) {
                     case "setText":
                          getMainTextbox().value = event.data.value
                          break
                     case "setAlphabetTable":
                         setCharMap(event.data.value)
                         break
                     case "setPosition":
                         caret.position(getMainTextbox(), event.data.value)
                         break
                    case "setReadOnly":
                        getMainTextbox().readOnly = event.data.value
                        break
                     default:
                         if (command !== undefined) {
                             console.warn("Unknown command " + command)
                         }
                         break
                 }
             }, false
        );
        window.onbeforeunload = () => {
            utils.sendEvent("closed", getMainTextbox().value);
        }
    }, [])

    const updateCharMap = (newCharMap) => {
        setCharMap(newCharMap);
        if (newCharMap === defaultCharMap) {
            localStorage.removeItem(config.charMapName);
        } else {
            localStorage.setItem(config.charMapName, JSON.stringify(newCharMap));
        }
    }

    const onCharMapChange = (idx, event) => {
        const newCharMap = charMap.map((row, index) => {
            if (index === idx)
                return [event.target.value, row[1]]
            return row
        })
        updateCharMap(newCharMap)
    }

    const onUploadCSV = async (event) => {
        const file = event.target.files[0]
        const text = await file.text()
        let newCharMap = utils.parseCSV(text)
        updateCharMap(newCharMap)
    }

    const onResetClicked = () => {
        updateCharMap(defaultCharMap)
    }

    const onEmbedSaveButtonClicked = () => {
        utils.sendEvent("save", getMainTextbox().value);
    }

    const onTransformText = () => {
        const textbox = getMainTextbox()
        const cursor_position = caret.position(textbox).pos
        let textChanged = true
        let text = textbox.value
        // First find all the ` and move it one step forward to comply with unicode standard
        text = text.replace(/`(.)/g, "$1`")

        while (textChanged) {
            textChanged = false
            for (const [fromChar, toChar] of charMap) {
                if (!fromChar) continue
                if (text.includes(fromChar)) {
                    text = text.replaceAll(fromChar, toChar)
                    textChanged = true
                }
            }
        }
        textbox.value = text
        caret.position(textbox, cursor_position)
    }

    if (config.isEmbed) return (
        <div className="container-fluid">
            <div className="row">
                <EditorTextbox charMap={charMap} />
                {
                    config.saveButtonLabel ?
                        <button className="btn btn-primary" onClick={onEmbedSaveButtonClicked}>
                            {config.saveButtonLabel}
                        </button>
                        : null
                }
            </div>
        </div>
    )

    return (
        <div className="container">
            <div className="row">&nbsp;</div>
            <div className="row">
                <h1 className="text-center">{title || "Loading ..."}</h1>
            </div>
            <div className="row">
                <div className="col-12">
                    <EditorTextbox charMap={charMap} />
                </div>
            </div>
            <div className="accordion" id="alphabet_table_container">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#acc_alphabet_table"
                            aria-expanded="true" aria-controls="Customize alphabet table"
                            disabled={config.expandAlphabetTable}
                        >
                            Customize Alphabet table
                        </button>
                    </h2>
                    <div
                        className={"accordion-collapse " + (config.expandAlphabetTable ? "" :  "collapse hide")}
                        id="acc_alphabet_table"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row alphabet_table">
                                <div className="note" role="alert">
                                    <i>* Changes made to this table are automatically saved</i>
                                </div>
                                {
                                    charMap.map(([charFrom, charTo], idx) => (
                                        <div className="col-2 text-center" key={`letter_${idx}`}>
                                            <b>{charTo}</b>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={charFrom}
                                                onChange={onCharMapChange.bind(null, idx)}/>
                                        </div>
                                    ))
                                }
                                <div className="col-12 clearfix">
                                    <hr/>
                                    <button className="btn btn-primary"
                                        onClick={utils.downloadCSV.bind(null, charMap, config.writingSystem)}
                                    >
                                        Export table
                                    </button>
                                    <label className="btn-secondary btn" htmlFor="importCsvBtn">
                                        Import table
                                    </label>
                                    <input className="d-none" id="importCsvBtn" type="file" onChange={onUploadCSV}/>
                                    <label className="btn-secondary btn" htmlFor="applyTableBtn">
                                        Apply
                                    </label>
                                    <input
                                        className="d-none"
                                        type="button"
                                        id="applyTableBtn"
                                        title="Replace all characters in the text with the equvilent ones in the table"
                                        onClick={onTransformText}
                                    />
                                    <button className="btn btn-danger float-end" onClick={onResetClicked}>
                                        Reset to defaults
                                    </button>
                                </div>
                                <div className="col-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
            </div>
            <br/><hr/><br/>
            <footer>
                Coptic Editor is released under GPLv2 license.
                Source code can be found here&nbsp;
                <a href="https://gitlab.com/ramast/coptic_editor" rel="noreferrer" target="_blank">
                    https://gitlab.com/ramast/coptic_editor
                </a><br/>
            </footer>
        </div>
    );
}

export default Editor;
