//create a user-defined function to download CSV file
import axios from 'axios';
import CSV from 'comma-separated-values';

const parentWin = window.opener || window.parent;


export function downloadCSV(csvFileData, writingSystem) {

    //define the heading for each row of the data
    // noinspection JSUnresolvedFunction
    let csv = CSV.encode(csvFileData, {header: false, lineDelimiter: "\n"})

    let hiddenElement = document.createElement("a")
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv)
    hiddenElement.target = "_blank"

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${writingSystem}_character_map.csv`
    hiddenElement.click()
    hiddenElement.remove()
}

export function parseCSV(csvString) {
    // noinspection JSUnresolvedFunction
    return CSV.parse(csvString, {cast: false, header: false})
}

export const loadWritingSystem =async (ws_name) => {
    let response;
    try {
        response = await axios.get(`/writing_systems/${ws_name}.json`)
    } catch (error) {
        return {
            "title": `${ws_name} Editor`,
            "defaultCharMap": [],
        }
    }
    return response.data;
}

export const parseUrlQuery = () => {
    let query = document.location.search
    if (!query) return {}
    return Object.fromEntries(query.substring(1).split("&").map(item => item.split("=")))
}

export const getConfiguration = () => {
    const query = parseUrlQuery();
    const writingSystem = query["ws"] || "coptic"
    const charMapName = `${writingSystem}_charMap`
    const defaultMap = JSON.parse(localStorage.getItem(charMapName) || "null") || [];
    const {placeholder = `You can write in ${writingSystem} here`} = query
    return {
        writingSystem,
        charMapName,
        defaultMap,
        isEmbed: query["embed"] || false,
        saveButtonLabel: query["saveButton"] || null,
        initialText: query["initialText"] || "",
        placeholder: placeholder,
        rows: query["rows"] || 3,
        fixedRows: query["fixedRows"] || false,
        caretPositionEvent: query["caretEvent"],
        textChangeEvent: query["changeEvent"],
        blurEvent: query["blurEvent"],
        readonly: query["readonly"] || false,
        expandAlphabetTable: query["expandAlphabetTable"] || false,
    }
}

export const sendEvent = (type, value) => {
    if (!parentWin) return;
    // If this is part of an iframe or popup, send parent window updates about current state
    // parent window could then handle these events by doing something like this
    // window.addEventListener('message', function(e) {console.log(e.data)}, false);
    parentWin.postMessage({type, value}, "*")
}
